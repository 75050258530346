.container {
  width: 100%;
}

.toLeftSide {
  text-align: left;
}

.title {
  margin-bottom: 0.7em;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 1.2em;
  color: #4e4e4e;
  overflow-wrap: break-word;
}

.description {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.75em;
  color: #5a5a5a;
}

.expandButton {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.875em;
  line-height: 1.375em;
  color: #5a5a5a;
  cursor: pointer;
}
