.wrapper {
  height: var(--doc-height);
  background-color: #f5f3ed;
  text-align: center;
  padding-top: 42px;
}

.pageWithHeaderWrapper {
  padding-top: 82px;
}

.logo {
  height: 45px;
  margin-bottom: 90px;
}

.logoImage {
  height: 100%;
}

.errorContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #ffffff;
  background: #ffffff;
  box-shadow: 0 0 35px rgba(152, 152, 152, 0.25);
  border-radius: 20px;
  max-width: 40vw;
  min-width: 300px;
  min-height: 300px;
  padding: 40px 58px 54px 58px;
  text-align: center;
}

.title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #9a2828;
  margin-bottom: 16px;
}

.reason {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57em;
  color: rgba(0, 0, 0, 0.75);
  opacity: 0.8;
  margin-bottom: 32px;
}

@media (max-width: 992px) {
  .errorContainer {
    max-width: 90vw;
    padding: 20px;
  }
}
