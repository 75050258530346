.groupedContainer {
  align-self: center;
  padding: 10px;
  width: 85%;

  .groupHeader {
    background-color: #96b5f0;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
  }
}

.groupedTestItem {
  &:nth-child(even) {
    background-color: #fafafa;
  }
}

@media (max-width: 430px) {
  .groupedContainer {
    padding: 0px;
    width: 100%;
  }
}
