.rowContainer {
  margin: 30px 0 10px 0;
  height: 100%;
}

.imageContainer {
  padding-bottom: 1em;
  height: 100%;
}

.image {
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #d0d7de;
}

.ant-form-item-control-input {
  height: 100%;
}

.inRow {
  display: flex;
  flex-direction: row;
}

.center {
  text-align: left;
}

.nextButton {
  background: #c39445;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.uploaderContainer {
  width: 100%;
  min-height: 420px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
}

.uploadIcon {
  padding-top: 120px;
}

.imgContainer {
  padding-top: 80px;
}

.uploadTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #505050;
}

.photoUploader {
  width: 200px;
  min-height: 420px;
}

.errorUpload {
  border-color: red;
}

.dropdown {
  background-color: #ffffff;
  border-radius: 3px;
  text-align: left;
  color: #444444;
  border: 1px solid #d0d7de;
}

.buttonWithAlertContainer {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.alertContainer {
  min-width: calc(100% - 140px);
  text-align: left;
}

.mdEditor {
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.mdEditor::after {
  align-self: end;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-length);
  background-color: transparent;
  pointer-events: none;
}

.fieldset {
  legend {
    font-weight: 800;
    color: #4e4e4e;
    font-size: 0.875rem;
    border: none;
  }
}

.switchContainer {
  display: flex;
  gap: 10px;

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  :global(.ant-form-item-control-input) {
    min-height: auto;
  }
}

.notificationSettingContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 1rem;
}

.daysContainer {
  display: flex;
  gap: 5px;

  p {
    margin-top: 5px;
  }

  :global(.ant-input-number) {
    width: 50px;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  :global(.ant-form-item-explain-error) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8em;
    text-wrap: nowrap;
  }
}
