.sidebarMenu {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 14px;
}

.menuTitle {
  padding-left: 12px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.75);
}

.menuItemsContainer {
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;

  color: #928a72;
}

.menuItemsContainer li {
  margin: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  margin: 0;
  font-weight: 700;
  color: #c39445;
  background-color: #f0ebdd;
  border-radius: 3px;
  border-left: 3px solid #c39445;
}

.menuItemsContainer .ant-menu-item:hover {
  font-weight: 700;
  color: #c39445;
}
