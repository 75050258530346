.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assessmentEditHeader {
  margin: 30px;
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toLeft {
  margin-right: 30px;
  float: right;
}
.removeButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-right: 2.5em;
  min-height: 40px;
}

/*----tabs-------*/
.tabTitleContainer {
  color: #253955;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tabName {
  margin: auto;
}

.tabNameWithIcon {
  padding-left: 2em;
}

.tabIconContainer {
  min-width: 2em;
  color: #ffc601;
  margin-left: 4px;
}

.jobDescriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 2em;
  margin-bottom: 1em;
}
.jobDescriptionComponent {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.75em;
  color: #5a5a5a;
}
.jobDescriptionComponent span {
  font-weight: 700;
  font-size: 16px;
}

@media print {
  .assessmentEditHeader {
    display: none;
  }
}
