.optionTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 2em;
  text-transform: uppercase;
}

.optionItem {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.14em;
}

.categoryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.invite {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inviteIconGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.link {
  color: #4e4e4e;
}
