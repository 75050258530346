.noInvites {
  color: #4e4e4e;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.invitesListContainer {
  text-align: left;
  color: #122646;
  font-size: 16px;
  font-weight: 500;
}

.invitesListContainer ul {
  list-style-type: none;
}

.invitesListContainer ul li {
  padding-bottom: 10px;
}

.invitesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 30px;
  padding-bottom: 30px;
}

.logoContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 27px 13px;
  max-height: 82px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 99;
}

.mobilePspLogo {
  margin-top: 5px;
  height: 25px;
}

.testMobilePspLogo {
  height: 40px;
}
