.printReportContainer {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  color: rgba(0, 0, 0, 0.75);
}
.reportHeader,
.reportFooter {
  width: 100%;
  position: fixed;
  background-color: #ffffff;
}
.reportHeader {
  top: 0;
  padding-top: 3px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  color: rgba(0, 0, 0, 0.75);
}

.reportFooter {
  bottom: 0;
}

.reportContent {
  margin: 20px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.75);
  width: calc(210mm - 1in);
}

.nameMatchRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.candidateName {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}
.match {
  padding: 11px 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  border-radius: 4px;
}
.assessmentDetails {
  .assessmentName {
    font-size: 1.125rem;
  }

  .jobDetail {
    font-size: 0.875rem;
  }
}

.charts {
  page-break-inside: avoid;
  margin-bottom: 20px;
  padding-top: 20px;
  width: inherit;
}
.chartsInRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.footerWrapper {
  padding-top: 20px;
}

.footerRowContainer {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}
.logoContainer {
  max-height: 24px;
  text-align: end;
}

.emptyHeader {
  min-height: 62px;
  background-color: #ffffff;
}
.emptyFooter {
  min-height: 62px;
  background-color: #ffffff;
}

@media screen {
  table {
    height: 0;
  }
  .reportFooter,
  .reportHeader {
    display: none;
  }
  .emptyHeader,
  .emptyFooter {
    display: none;
  }
  .reportContent {
    height: 0;
    margin: 0;
  }
  .printReportContainer {
    height: 0;
  }
  .reportSuggestions {
    display: none;
  }
}

@media print {
  ul,
  h2,
  h3,
  h4,
  h5 {
    page-break-inside: avoid;
  }
  h2,
  h5 {
    page-break-after: avoid;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);
  }
  p {
    widows: 4;
    orphans: 3;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
