.analysisContainer {
  padding: 0 15px 15px;
}

.analysisHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px 15px;
  margin: 0 20px 10px;
  background: #e1ebfe;
  border-radius: 5px;
}

.analysisHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 24px;
}

.analysisHeader h1 {
  margin-left: 0;
  padding: 10px 18px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  background: #ffffff;
  border-radius: 3px;
}

.analysisHeader h1 span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.buttonTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(256, 256, 256, 0.2);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.tabTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.scaleModal {
  :global(.ant-modal-footer) {
    padding: 0;
    border: none;
  }

  :global(.ant-modal-content) {
    border-radius: 5px;
  }

  :global(.ant-modal-header) {
    border-radius: 5px;
    border-color: transparent;
    padding: 32px 40px 8px;

    :global(.ant-modal-title) {
      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 1.36em;
      color: rgba(0, 0, 0, 0.75);
    }
  }

  :global(.ant-modal-body) {
    padding: 8px 40px 32px;
  }
}
