.assessment-button-container {
  margin-bottom: 24px;
}

/*.assessment-table-block {*/
/*  background: #ffffff;*/
/*  box-shadow: 0 0 40px #e3e3e3;*/
/*  border-radius: 3px;*/
/*  padding: 30px 19px 30px 29px;*/
/*}*/

.ant-table-thead > tr > th {
  background: #ffffff;
}

/*.assessment-table-title {*/
/*  font-size: 28px;*/
/*  font-weight: 800;*/
/*  margin-bottom: 30px;*/
/*  justify-content: space-between;*/
/*  text-align: left;*/
/*}*/

.assessment-table-title > div > article {
  color: #4e4e4e;
}

.assessment-table-filter {
  font-size: 14px;
  font-weight: 700;
  flex-direction: column;
  align-self: center;
  width: 72px;
  padding: 0;
}

.assessment-table-search {
  flex-direction: column;
  align-self: center;
}

.assessment-table-search input {
  border-radius: 10px;
}

.assessment-table-search .ant-input-group-addon {
  border-radius: 10px;
}

/*.assessment-column-name {*/
/*  cursor: pointer;*/
/*  font-size: 14px;*/
/*  font-weight: 600;*/
/*}*/

.assessment-column {
  font-size: 14px;
  font-weight: 400;
  color: #4e4e4e;
}

.assessment-progress-block {
}

.assessment-progress-block-title {
  font-size: 28px;
  font-weight: 800;
  text-align: left;
  color: #4e4e4e;
}

.assessment-progress-block-card {
  background: #fcfbf7;
  border-radius: 5px;
  height: 131px;
  margin-bottom: 24px;
}

#my-assessment-table .ant-table-thead > tr > th {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

#my-assessment-table .ant-table-tbody > tr > td {
  background-color: #ffffff;
}

.add-assessment-btn {
  background: #c39445;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}
