.chartHeader {
  margin: auto;
  align-self: center;
}

.chartHeader .ant-select {
  border: 1px solid #efefef;
}

.chartHeader .ant-radio-group {
  padding: 5px 0;
  background-color: #f4f8fe;
  border-radius: 40px;
}

.chartHeader .ant-radio-button-wrapper {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #122646;
  background: transparent;
  border-color: transparent;
}

.chartHeader .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-radius: 3em;
  font-weight: 600;
}

.chartHeader .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.chartHeader .ant-radio-button-wrapper:first-child {
  border-color: transparent;
  border-radius: 2.2em;
}
.chartHeader .ant-radio-button-wrapper:last-child {
  border-color: transparent;
  border-radius: 2.2em;
}

.chartHeader .ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  background-color: transparent;
  border-color: transparent;
}

.anticon-close,
.anticon-close:hover {
  opacity: 1;
  font-size: 12px;
}
