.timerContent {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  color: #525b69;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.time {
  text-transform: uppercase;
}

.alertTime {
  color: #c02f2f;
}

.alertTime img {
  width: 20px;
  height: 20px;
}
