.container {
  margin: 20px 0;
}

.innerTabsContainer {
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 5px;
  padding: 30px 20px 5px 30px;
}

.tabLink {
  color: #253955;
}
