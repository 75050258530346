.questionContainer {
  padding: 0 0 16px 0;
}

.questionAlign {
  display: flex;
  align-items: center;
}

.question {
  font-family: muli, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 30px;
  color: #4e4e4e;
}

div.questionMarkdownWrapper {
  font-family: muli, sans-serif;
}

.questionTextWrapper {
  fieldset {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    padding: 0 1rem 1rem;

    &[data-orientation="horizontal"] {
      flex-direction: row;
      gap: 10px;
    }

    label {
      width: fit-content;
    }

    legend {
      border-bottom: none;
      color: inherit;
    }

    [type="radio"] + span:focus,
    [type="radio"] + label:focus {
      outline: 2px solid blue;
    }

    [type="radio"] {
      position: fixed;
      clip: rect(0, 0, 0, 0);
    }

    [type="radio"] + span,
    [type="radio"] + label {
      margin-bottom: 0.5rem;
      align-items: center;
      background-color: #efefef;
      border-radius: 100px;
      display: inline-flex;
      font-size: 0.875rem;
      font-weight: 300;
      padding: 8px 14px 8px 7px;
      text-transform: uppercase;
    }

    [type="radio"] + span::before,
    [type="radio"] + label::before {
      background-color: #fff;
      border-radius: 50%;
      border: 0.25em solid #fff;
      content: "";
      height: 1.6em;
      margin-right: 8px;
      transition: 0.5s ease background-color;
      width: 1.6em;
    }

    [type="radio"]:checked + span,
    [type="radio"]:checked + label {
      background-color: rgba(24, 144, 255, 0.2);
    }

    [type="radio"]:checked + span::before,
    [type="radio"]:checked + label::before {
      background-color: black;
    }
  }
}

.questionTextWrapper :global(.two-way-match) {
  display: flex;
  align-self: center;
  gap: 10px;
}

.answerChoicesContainer {
  font-family: muli, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.25rem;
}

/* This won't be needed once less is added and the default font-size is overridden to use rems */
.answerChoicesContainer :global(.ant-radio-group),
.answerChoicesContainer :global(.ant-radio-wrapper),
.answerChoicesContainer :global(.ant-badge) {
  font-size: 1.25rem;
}

.questionSmall {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}

.desktopQuestion {
  font-size: 32px;
  line-height: 40px;
}
.desktopQuestionSmall {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.analysisDesktop {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

@media (max-width: 992px) {
  .desktopQuestion {
    font-size: 22px;
    line-height: 30px;
  }
  .desktopQuestionSmall {
    font-size: 18px;
    line-height: 23px;
  }

  .stage {
    padding-bottom: 0;
    padding-top: 0;
  }

  .desktopStage span {
    font-size: 14px;
    line-height: 18px;
  }
}
