.cardContainer {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  max-height: 350px;
  max-width: 440px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 35px rgba(152, 152, 152, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.cardContainerRescinded {
  background-color: #eee;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  gap: 5px;
  border-radius: 30px;
  float: right;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.accepted {
  background: #fff3d5;
  color: #c99a25;
}
.started {
  background: #ddebff;
  color: #397ad3;
}
.in_progress {
  background: #ddebff;
  color: #397ad3;
}
.done {
  background: #f0f0f0;
  color: #747474;
}

.rescinded {
  background: #f4a7a7;
  color: #000000;
}

.headerContainer {
  text-align: left;
}

.state {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #c4a567;
}

.inProgress {
  color: #68c971;
}

.finished {
  opacity: 0.5;
}

.contentContainer {
  margin: 15px 10px;
  overflow-y: auto;
}
.statisticsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;
}

.assessmentInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.assessmentTitle {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #4e4e4e;
  max-height: 64px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionContainer {
  max-height: 72px;
  margin-bottom: 20px;
  overflow-y: auto;
  text-align: left;
  font-weight: 400;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 22px;
  color: #5a5a5a;
}

.descriptionContainer p {
  max-width: 95%;
}

.buttonContainer {
  display: flex;
  justify-content: right;
  width: 100%;
}
