.singleLayout {
  margin: 15px 30px;
  background-color: #ffffff;
  box-shadow: 2px 2px 29px #dbd1c0;
  border-radius: 5px;
}

.wrapper {
  padding: 15px 30px;
}

.screenContainer {
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 10px;
}

.buttonWithNameBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  margin-bottom: 16px;
}

.candidateName {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  padding-left: 12px;
  margin: 12px 0;
  font-size: 18px;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.75);
  overflow-wrap: break-word;
}

.chartHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 20px;
}
.selectedUsers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
}
.checkboxSize {
  min-width: 160px;
}

.rowContainer {
  margin: 0 30px 20px 30px;
  min-height: 50vh;
}

.cardBackground {
  border-radius: 5px;
  background-color: #ffffff;
}

.cardBackground :hover {
  border-color: transparent;
}

.warningContainer {
  font-size: 18px;
  line-height: 1.178em;
  font-weight: 600;
  text-align: center;
  padding: 1em 3em;
  color: #c02f2f;
  background-color: #c02f2f1a;
}

.sidebarWrapper {
  overflow: auto;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f5f3ed;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 13px 12px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
}
.contentChild {
  overflow-y: auto;
  flex-grow: 2;
  margin: 20px 0 12px;
}

@media screen {
  .printContainer {
    visibility: hidden;
    height: 0;
    overflow: auto;
  }
}

@media print {
  .printContainer {
    visibility: visible;
    height: auto;
  }
  .wrapper {
    padding: 0;
  }
  .screenContainer {
    display: none;
  }
  .singleLayout {
    display: none;
  }
}
