.inputTitle .ant-select:not(.ant-select-disabled) .ant-select-selector {
  border-radius: 3px;
}

.inputTitle .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-radius: 3px;
}
.inputTitle {
  margin-bottom: 1em;
}

.inputTitle input,
.inputTitle textarea {
  font-size: 14px;
  border: none;
  padding: 0.86em 1.14em;
  border-radius: 0.25em;
}

.inputTitle > .ant-form-item .ant-form-item-label {
  padding: 0;
}

.inputTitle label {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 1.5em;
  color: #4e4e4e;
}
