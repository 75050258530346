.container {
  height: var(--doc-height);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 12vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.content h1 {
  text-align: center;
}

.buttonGroup {
  margin: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.buttonContainer {
  margin: 20px;
}
