.overallContainer {
  width: 100%;
  font-size: 16px;
  min-height: 2em;
  padding: 0 2em 1em;
}

.overallTextBlock {
  margin-top: 0;
}

.Unfavorable {
  color: #c35445;
  background-color: #c354451a;
}
.Mixed {
  color: #f6b444;
  background-color: #f6b4441a;
}
.Favorable {
  color: #d6c746;
  background-color: #d6c7461a;
}
.Exceptional {
  color: #66cc99;
  background-color: #66cc991a;
}
.overallContainer h1 {
  font-size: 24px;
  line-height: 1.178em;
  font-weight: 700;
  text-align: center;
  padding: 0.75em;
  margin: 0 0 0 2em;
  border-radius: 0.2em;
}
.overallContainer span {
  font-size: 24px;
  line-height: 1.178em;
  font-weight: 500;
  color: #4e4e4e;
}

.overview {
  font-size: 24px;
  font-weight: 700;
  color: #4e4e4e;
  line-height: 1.178em;
  margin-bottom: 0.5em;
}

.overallParagraph {
  margin-bottom: 1em;
}

.overallParagraph p {
  margin-bottom: 1em;
  font-weight: 400;
  line-height: 1.75em;
  color: #535353;
}

.text {
  text-align: center;
  margin-top: 3em;
}

.chartsContainer {
}

@media print {
  .overview {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);
  }
}
