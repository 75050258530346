.testElementContainer {
  align-items: center;
  display: flex;
  padding: 5px;

  .questionNumber {
    font-size: 0.75rem;
    margin-left: 1rem;
    min-width: 25px;
  }
}

.testElementContainer label {
  flex: 1 1;
  margin: 0 5px;
  text-align: right;
  user-select: none;
}

.testElementContainer :global(.ant-checkbox-wrapper) {
  flex: 0;
}

.testElementContainer label:last-child {
  text-align: left;
}

@media (max-width: 430px) {
  .testElementContainer {
    padding-inline: 0px;

    .questionNumber {
      margin-left: 5px;
      min-width: 20px;
      font-size: 0.7rem;
    }

    label {
      font-size: 0.8em;
    }
  }
}
