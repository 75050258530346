.tagsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
}

.tag {
  display: flex;
  gap: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #ffffff;
  border-radius: 5px;
}
