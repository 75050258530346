.barChartTickLabel {
  height: 100%;
  margin-right: 0.5em;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.1em;
  text-align: end;
}

.textExplanation {
  text-align: center;
  padding: 20px 0;
}

@media print {
  .chartContainer {
    break-inside: avoid;
  }
}
