/*-------------------------table-----------------------*/

.ant-table table {
  border-collapse: collapse;
}

.ant-table-thead th.ant-table-column-sort {
  background: transparent;
}

td.ant-table-column-sort {
  background: transparent;
}

.ant-table-column-title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
  color: #4e4e4e;
}

/*-------------------------pagination-----------------------*/

.ant-table-pagination {
  align-items: center;
}

.ant-table-pagination.ant-pagination {
  margin: 0.625em 0;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  color: #253955;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.5em;
  color: #535353;
  padding: 0;
  margin-right: 0;
  border: none;
}

.ant-pagination-item {
  border-left: #ffffff;
  border-right: #ffffff;
  border-top: #ffffff;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.5em;
  color: #535353;
  padding: 0;
  margin-right: 0.5em;
}

.ant-pagination-item .ant-pagination-item-active {
  border-color: #ffffff;
  border-bottom: 0.2em solid var(--primary-color);
}

.ant-pagination-item-active {
  border-color: #ffffff;
  border-bottom: 0.2em solid var(--primary-color);
}

.ant-pagination-item:hover {
  border-color: white;
  color: #535353;
}

.ant-pagination-item-active a {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.5em;
  color: var(--primary-color);
  padding: 0;
  margin-right: 0;
}

.ant-pagination-item-active:hover a {
  color: #535353;
}

.ant-pagination-item a {
  color: #535353;
  text-decoration: none;

  &:hover {
    color: #0b182d;
  }
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border: none;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

/*-------------------------upload-----------------------*/

.ant-upload.ant-upload-drag {
  border: none;
}

/*-------------------------Menu-----------------------*/

.ant-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*-------------------------Switch-----------------------*/

.ant-switch {
  background: rgba(37, 57, 85, 0.1);

  .ant-switch-handle::before {
    background-color: var(--primary-color);
  }

  .ant-switch-inner {
    color: var(--primary-color);
  }
}

.ant-switch-checked {
  background: var(--primary-color);

  .ant-switch-handle::before {
    background-color: #ffffff;
  }

  .ant-switch-inner {
    color: #ffffff;
  }
}

.ant-switch:focus {
  box-shadow: none;
  outline-offset: 2px;
  outline: 2px solid var(--primary-color);
  transition: none;
}

/*-------------------------Sider-----------------------*/

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*-------------------------Avatar-----------------------*/

.ant-avatar {
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------------------------tabs-----------------------*/

.ant-tabs {
  line-height: inherit;
}

.ant-tabs-tab {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2em;
  text-transform: uppercase;
  background: transparent;
  color: var(--primary-color);
  min-height: 3.5em;
  min-width: 12em;
  margin: auto;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: transparent;
  border: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primary-color);
  background: #e7e3d7;
  font-weight: 700;
  opacity: 1;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
  font-weight: 700;
  opacity: 1;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-radius: 6px 6px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0.625em;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  background: #253955;
  height: 0.25em;
}

.ant-tabs-tab-btn {
  margin: auto;
  opacity: 0.5;
}
.ant-tabs-nav-list {
  margin-left: 30px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-color: transparent;
}

/*-------------------------radio-----------------------*/

.ant-radio-wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 11px 14px 11px 7px;
  border-radius: 100px;
  background: #efefef;
  color: #000000;
  text-transform: uppercase;
}

.ant-radio-wrapper span p {
  min-width: 2em;
  margin: auto;
}

.ant-radio-wrapper-checked {
  background: rgba(24, 144, 255, 0.2);
}

.ant-radio-inner {
  top: -0.25em;
  width: 1.6em;
  height: 1.6em;
  border: none;
}

.ant-radio-checked .ant-radio-inner {
  border: none;
}

.ant-radio-inner::after {
  width: 1.6em;
  height: 1.6em;
  margin-top: -0.8em;
  margin-left: -0.8em;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #253955;
}

.ant-radio-checked::after {
  border: 1px solid #253955;
  top: -0.2em;
}

.ant-radio:hover .ant-radio-inner {
  border: none;
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 1px var(--secondary-color);
}

/*-------------------------button-----------------------*/

/* https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */
.ant-btn-primary[disabled] {
  pointer-events: none;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border-color: rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.06);
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 7px;
}

.ant-btn-default {
  border: 1px solid var(--primary-color);

  &:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
}

.ant-btn-primary {
  text-shadow: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #dee0e3;
  box-shadow: none;
  color: var(--primary-color);
}

/*------------------------select-----------------------*/

.ant-select {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  color: #444444;
  cursor: pointer;
  width: 100%;
  border-radius: 50px;
}

.ant-select-multiple .ant-select-selection-placeholder {
  padding: 0.75em;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 50px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

.ant-select-selection-item {
  font-weight: inherit;
}

.ant-select-arrow {
  right: 1.25em;
  color: #253955;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
  border-color: transparent;
  border-radius: 0.25em;
  height: 3.25em;
  padding: 0 1.14em;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 0.5em;
  padding-left: 0.313em;
}

.ant-select-selection-placeholder {
  margin: auto;
  color: rgba(0, 0, 0, 0.4);
}
.ant-select-selection-item {
  margin: auto;
}

.ant-select-multiple .ant-select-selection-item {
  text-transform: uppercase;
}

.ant-select:hover .ant-select-clear {
  right: 70px;
}
.ant-select-auto-complete .ant-select-clear {
  right: 70px;
}
.ant-select:not(.ant-select-disabled) .ant-select-selector {
  border-radius: 50px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-radius: 50px;
}

/*ant-select ant-select-in-form-item ant-select-focused ant-select-open ant-select-show-search*/
/*------------------------forms------------------------*/

.ant-form-item-control-input-content,
.ant-form-item-control-input {
  height: 100%;
}

.ant-form-item-label > label {
  height: inherit;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  font-size: 14px;
  padding: 0 0 0.6em;
  line-height: 1.5em;
}

.ant-input {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  color: #444444;
}

.ant-input-affix-wrapper {
  border-color: transparent;
  font-size: 14px;
  padding: 0.86em 1.14em;
  border-radius: 0.25em;
}

.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused {
  background-color: rgba(24, 144, 255, 0.08);
}

.ant-tooltip {
  font-size: 14px;
}

.ant-input-number:hover,
.ant-input-number:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--primary-color);
}

/*-------------------------------------divider---------------------------*/

.ant-divider-horizontal {
  border-color: #efefef;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-color: #efefef;
  border-width: thick;
}

/*---------------------modal--------------------*/

.ant-modal-footer {
  box-shadow: 0 -2px 16px rgba(213, 213, 213, 0.35);
}

.ant-menu-item a,
.ant-tabs-tab-btn a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.ant-dropdown-trigger:hover {
  background: none;
  color: var(--accent-color);
}

.ant-modal-wrap {
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
}

.ant-modal {
  position: initial;
  padding-bottom: 0;
}

/*---------------------slider--------------------*/
.ant-slider-handle {
  border-color: var(--primary-color);
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--primary-color);
}

.ant-slider-track {
  background-color: var(--primary-color);
}

.ant-slider-dot-active {
  border-color: var(--primary-color);
}
