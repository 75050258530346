.desktopContainer {
  max-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.componentWrapper {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

.finishComponent {
  height: 100%;
  overflow-y: hidden;
}

.mobileStatisticsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
}

.descriptionContainer {
  text-align: left;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #5a5a5a;
  font-size: 14px;
  line-height: 24px;
}

.desktopDescription {
  font-size: 16px;
  line-height: 26px;
}

.desktopButtonContainer {
  margin-top: 57px;
  width: 40%;
}

.title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  text-align: left;
  margin: 50px 0 20px;
  font-size: 26px;
  line-height: 33px;
  overflow-wrap: break-word;
}

.desktopTitle {
  font-size: 36px;
  line-height: 45px;
}

.introOutroTexts {
  flex: 1 1 1px;
  text-align: left;
  margin: 20px 0;
}
.outroText {
  min-height: calc(100vh - 400px);
  overflow-y: auto;
}

@media (max-width: 992px) {
  .introOutroTexts {
    margin-left: 12px;
    margin-right: 12px;
  }
  .mobileStatisticsContainer {
    margin: 0 12px;
  }
  .title {
    margin: 20px 12px 20px;
  }

  .desktopTitle {
    font-size: 26px;
    line-height: 33px;
  }
  .desktopDescription {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .introOutroTexts {
    margin-left: 0;
    margin-right: 12px;
  }
  .outroText {
    min-height: calc(100vh - 100px);
  }
  .mobileStatisticsContainer {
    margin: 0;
  }
  .title {
    margin: 10px 0;
  }
  .desktopButtonContainer {
    margin-top: 0.5em;
  }
}

@media (max-height: 599px) and (max-width: 992px) and (orientation: landscape) {
  .desktopContainer {
    max-height: calc(100vh - 200px);
  }
}
