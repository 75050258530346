.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 40px;
  border: none;
  background: #f9f9f9;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #253955;
  padding-left: 19px;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
  border: none;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-height: 40px;
  min-width: 60px;
  border: none;
  background: #f9f9f9;
}

.ant-input-group-addon {
  background: transparent;
}
