.userAssessmentInput {
  display: block;
}

.userAssessmentInput > * {
  margin-bottom: 6px;
}

.background {
  background-color: #f5f3ed;
  background-image: url(src/assets/image/pattern.png);
  height: 100vh;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.formContainer {
  width: 55%;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .formContainer {
    width: 85%;
  }
}
