@import "~antd/dist/antd.css";
@import "./ant-reset.css";

:root {
  --doc-height: 100%;
}

.App {
  height: 100%;
  margin: 0 auto;
}

.main-container {
  min-height: var(--doc-height);
  background: var(--light-background-color);
  padding: 82px 0 0 0;
}

.inner-container {
  margin: 30px 30px 0;
}

.ant-select {
  border-bottom: 1px solid #f3f3f3;
  border-radius: 3px !important;
}

.ant-select .ant-select-selector {
  border-radius: 3px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5em;
  height: 0.5em;
  margin-block: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  width: 0.5em;
  border-radius: 1em;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0.5in;
  }
  .main-container {
    padding: 0;
    background: #ffffff;
  }
  .App {
    background-color: #ffffff;
  }
}
