.tooltipContainer {
  max-width: 250px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cccccc;
}

.explanation {
  color: #4e4e4e;
  font-size: 15px;
  line-height: 1.125;
  font-weight: 400;
}
