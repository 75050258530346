.testElementContainer {
  font-size: 14px;
  padding: 1px;
}

.radioGroup {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
}
.textGroup {
  margin-top: 1em;
}
.textGroup p {
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
}

.radioGroup .ant-radio-button-wrapper {
  border-radius: 3em;
  box-sizing: content-box;
  border-style: solid;
}

.radioGroup .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked:first-child {
  border-color: #d9d9d9;
  background-color: #e6e6e6;
}

.radioGroup .ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  background-color: #bacdf0;
}

.radioGroup .ant-radio-button-wrapper:first-child {
  border-left-width: 8px;
  border-color: #bacdf0;
}

.radioGroup .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.radioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #253955;
  background: #bacdf0;
  border-color: #253955;
}
.radioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #253955;
  background: #bacdf0;
  border-color: #253955;
}

.radioGroup
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #253955;
}

.radioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 1px var(--accent-color);
}

.large {
  height: 14px;
  padding: 0 7px;
  border-width: 8px;
  background-color: #bacdf0;
  border-color: #bacdf0;
}
.medium {
  height: 10px;
  padding: 0 5px;
  border-width: 7px;
  background-color: #cbdbf8;
  border-color: #cbdbf8;
}
.small {
  height: 6px;
  padding: 0 3px;
  border-width: 6px;
  background-color: #e1ebfe;
  border-color: #e1ebfe;
}

.largeParagraph {
  width: 30px;
}
.mediumParagraph {
  width: 24px;
}
.smallParagraph {
  width: 18px;
}

.badges {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 0;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .testElementContainer {
    font-size: 12px;
  }
  .radioGroup {
    font-size: 12px;
    margin-top: 1.5em;
  }
  .divider {
    font-size: 12px;
    margin: 1.5em 0;
  }
  .statementContainer {
    font-size: 12px;
  }
}
