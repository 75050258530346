.actionButtons {
  display: flex;
  justify-content: center;
  align-content: center;
}

@media (max-width: 430px) {
  .actionButtons {
    margin: auto;
    gap: 12px;
    flex-direction: column;
  }

  .actionButtons > button {
    margin: 0 !important;
  }
}
