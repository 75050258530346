.container {
  padding: 2px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
  /*max-width: 100%;*/
}

.draggerContainer {
  margin: auto;
}

.inRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.15em;
  text-transform: uppercase;
  color: #4e4e4e;
}

.deleteButton {
  border: none;
  color: #afafaf;
  background: none;
}

.deleteIcon {
  font-size: 23px;
}

.questionButton {
  min-width: 155px;
  min-height: 45px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 3px;
  border: none;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #253955;
}

.buttonTitle {
  margin: 8px 0 8px 15px;
}

.arrow {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  margin: 8px;
}

.arrowDown {
  transform: rotate(135deg);
}

.turnUp {
  transform: rotate(-45deg);
}

.questionContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 30px;
}

.normSelector {
  min-width: 250px;
  margin-left: 30px;
  background-color: #f9f9f9;
  border-radius: 0.25em;
  text-align: left;
  color: #253955;
  font-weight: 500;
}

.testOptions {
  min-width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loadingContainer {
  width: 100%;
}
