.userAvatarButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  border: none;
  border-radius: 100px;
  padding: 0 6px;
  box-shadow: none;
  color: #122646;
}

.userAvatarButton:hover,
.userAvatarButton:active,
.userAvatarButton:focus {
  box-shadow: none;
  color: var(--accent-color);
}

.avatarCircle {
  background: rgba(37, 57, 85, 0.1);
}

.avatarContainer {
  padding: 3px 5px 3px 0;
}
