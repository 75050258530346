.container {
  height: var(--doc-height);
  background-color: #ffffff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileFooter {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding: 30px 15px;
  background-color: #ffffff;
  box-shadow: 0 -2px 16px rgba(213, 213, 213, 0.35);
}

.mobileImageContainer {
  background-color: var(--light-background-color);
  width: 100%;
  height: 180px;
}

.desktopImageContainer {
  background-color: var(--light-background-color);
  width: 50vw;
  height: 100vh;
  overflow: hidden;
}

.mobileImage {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktopImage {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.mobileContentContainer {
  width: 100%;
  margin-top: 17px;
  padding: 0 15px;
}

.desktopContentWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.desktopContentContainer {
  margin: 15px 27px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow-y: hidden;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logoContainer p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #bbbbbb;
}

.mobileLogoContainerHeight {
  height: 45px;
}

.desktopLogoContainerHeight {
  text-align: left;
  height: 65px;
  margin-bottom: 2em;
  padding-inline: 5px;
}

.desktopLogoContainerHeight p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #bbbbbb;
}

.mobileLogoFontSize {
  font-size: 12px;
  line-height: 15px;
}

.desktopLogoFontSize {
  font-size: 14px;
  line-height: 18px;
}

.mobilePspLogo {
  margin-top: 5px;
  height: 25px;
}

.desktopPspLogo {
  margin-top: 9px;
  height: 38px;
}

.desktopAssessmentViewElementContainer {
  text-align: left;
  height: 0;
  vertical-align: middle;
  margin-top: 150px;
}

.smallMargin {
  margin-top: 75px;
}

.desktopFooter {
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  padding: 22px;
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.mobileStatisticsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.mobileDescriptionContainer {
  text-align: left;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #5a5a5a;
}

.desktopButtonContainer {
  margin-top: 57px;
  width: 40%;
}

.testMobilePspLogo {
  height: 40px;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
}

.welcomeStartElement {
  overflow-y: auto;
}

.testWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: calc(100% - 80px);
  margin: 21px 0 15px 0;
  background: #ffffff;
  box-shadow: 0 0 35px rgba(152, 152, 152, 0.25);
  border-radius: 20px;
}

.welcomeFinishElement {
  justify-content: flex-start;
}

.testImageContainer {
  width: 100%;
  height: 20%;
}

.testImage {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testCompanyLogoContainer {
  margin: 10px 12px 15px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.testChildContainer {
  flex: 1 1 auto;
  display: flex;
  text-align: left;
  flex-direction: column;
  overflow-y: hidden;
  padding-inline: 5px;
}

.testElementsContainer {
  overflow-y: auto;
  flex: 1 1 1px;
  padding-right: 0.5em;
}

.testElementsContainerFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4em;
}

.smallMarginMobile {
  margin-top: 0;
}

.testButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  padding: 13px 12px;
  flex-direction: row-reverse;
  background-color: #ffffff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 -2px 16px rgba(213, 213, 213, 0.35);

  :global(#next),
  :global(:back) {
    width: 92px;

    & > span {
      padding-left: 0;
    }
  }

  :global(#next) {
    padding: 0.575em 0.75em 0.575em 1.25em;
  }

  :global(:back) {
    padding: 0.575em 1.25em 0.575em 0.75em;
  }
}

.paginationColor {
  background-color: var(--light-background-color);
}


@media (max-width: 992px) {
  .testChildContainer {
    margin: 5px 12px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .desktopContentContainer {
    margin: 15px 20px;
  }

  .desktopLogoContainerHeight {
    height: 45px;
  }

  .testChildContainer {
    margin: 15px 20px;
  }

  .desktopLogoFontSize {
    font-size: 12px;
    line-height: 15px;
  }

  .desktopPspLogo {
    height: 25px;
    margin-top: 5px;
  }

  .desktopAssessmentViewElementContainer {
    margin-top: 20px;
  }

  .desktopFooter {
    padding: 10px 20px;
  }
}
