.editorLabel {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.125em;
  line-height: 1.5em;
  color: #4e4e4e;
  text-align: left;
  padding: 0 0 1em 0;
}

.editor {
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 5px;
  padding: 30px 20px 20px 30px;
}

.buttonContainer {
  padding: 20px 0 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.alertContainer {
  min-width: calc(100% - 120px);
  text-align: left;
}

.competencyBlockContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.rangeBlock {
  min-width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.competencyName {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.2em;
  color: #4e4e4e;
  text-align: left;
}

.svgSize {
  height: 1.14em;
  width: 1.14em;
}

.descriptionBlock {
  margin-bottom: 2em;
}
