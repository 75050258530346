.competencyFormContainer {
  margin: 0 1.25em 1.25em;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.buttonContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.competencyHeaderBlock {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.competencySwitchGroup {
  display: flex;
  gap: 10px;

  & > p:first-of-type {
    font-weight: bold;
  }
}

.competencyName {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.5em;
  line-height: 1.25em;
  color: #4e4e4e;
  margin-bottom: 1em;
  text-align: left;
  padding-left: 0;
}

.alertContainer {
  min-width: calc(100% - 120px);
}
