.assessmentItemButton {
  margin-bottom: 20px;
}

.assessmentItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 0 19px #d9d1c3;
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.assessmentItemStatus {
  display: flex;
  justify-content: space-between;

  width: 100%;
  border-radius: 3px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 19px;
}

.assessmentItemStatus .assessmentItemStatusSettings > * {
  font-size: 16px;
  margin-left: 14px;
}

.assessmentItemContent {
  margin-top: 15px;
  text-align: left;
  width: 100%;
}

.assessmentItemContentTitle {
  font-family: "Raleway", serif;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  color: #122646;
}

.assessmentItemScores {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.assessmentItemScores .assessmentItemScoresItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin-bottom: 12px;
}

.assessmentItemScores span {
  font-size: 14px;
  margin-right: 10px;
}

.assessmentItemScores .assessmentItemScoresItem p {
  font-family: "Raleway", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.assessmentItemScores .assessmentItemProgressItem .ant-progress {
  padding: 0;
}

.assessmentItemScores .assessmentItemProgressItem {
  width: 50%;
  padding-right: 10px;
}
