.container {
  min-width: 100%;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0 0 40px #e3e3e3;
  border-radius: 5px;
  padding: 40px;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.photoContainer {
  background-color: #f9f9f9;
  border-radius: 3px;
  margin-right: 30px;
  width: 140px;
  height: 140px;
}

.inputTitle {
  margin-bottom: 10px;
}

.inputTitle input,
.inputTitle textarea {
  background-color: #f9f9f9;
  border: none;
  padding: 12px 16px;
  border-radius: 3px;
}

.inputTitle > .ant-form-item .ant-form-item-label {
  padding: 0;
}

.inputTitle label {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #4e4e4e;
}

.buttonContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.alertContainer {
  min-width: calc(100% - 120px);
}
