.overview {
  font-size: 24px;
  font-weight: 700;
  color: #4e4e4e;
  line-height: 1.178em;
  margin-bottom: 1.5em;
}

.container {
  padding: 2em;
}

.submitContainer {
  display: flex;
  justify-content: right;
  align-items: end;
  margin-top: 10px;
  flex-flow: column;
}

.aboutUpdateContainer {
  margin-bottom: 30px;
}
