.linksContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-height: 350px;
  background: #ffffff;
  box-shadow: 0 0 40px #e3e3e3;
  border-radius: 5px;

  a {
    padding-inline: 5px;
  }
}

.link {
  margin: 10px 30px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
}

.btnContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
}
