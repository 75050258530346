.tableBlock {
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 5px;
  padding: 30px 20px 5px 30px;
}

.auditTable {
  background: #ffffff;
  padding: 30px 20px 5px 30px;
}
.firstTable {
  padding-top: 0;
}
.lastTable {
  padding-bottom: 0;
}

.tableColumnName {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 1.5em;
  color: #253955;
  text-align: left;
}

.tableButtonsBlock {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
}

.actionsColumnWidth {
  min-width: 350px;
  width: 30%;
}

.text {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.tableColumn {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4e4e4e;
  text-align: left;
}

.tableColumnCode {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4e4e4e;
  text-align: left;
  width: 200px;
}

.tableLink {
  color: #253955;
}

.archived {
  text-decoration: line-through;
}

.ellipsisContent {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsisContent span {
  white-space: nowrap;
}

.tableUnitedColumn {
  font-size: 16px;
  font-weight: 600;
  padding-left: 16px;
}

.actionsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.minActionsGap {
  flex-wrap: nowrap;
  gap: 10px;
}

.tableColumnJobMatch {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4e4e4e;
  text-align: center;
}

.tableOrdinaryRow {
  border-bottom: 1px solid #f0f0f0;
}

.tableStartSectionRow {
  border-top: 5px solid #253955;
  border-bottom: 1px solid #f0f0f0;
}

.tableBreakAfterRow {
  border-bottom: 2px solid #253955;
}

.asteriskSize {
  font-size: 1.5em;
  line-height: 1em;
}

.toLeft {
  text-align: left;
}

.centred {
  text-align: center;
}

.manageButton {
  border: none;
  color: #b0b0b0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.resendButton {
  border: none;
  color: #122646;
  opacity: 1;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
}

.buttonNameContainer {
  margin: auto;
}
.buttonNameContainer a {
  color: #253955;
}
.disabledLink a {
  color: rgba(0, 0, 0, 0.25);
}

.buttonNameContainer a:hover {
  color: #40a9ff;
}

.iconStyleInvisible {
  font-size: 23px;
  color: transparent;
}

.dangerousColor {
  color: #f4320e;
}

.Unfavorable {
  border-color: #c35445;
  background-color: #c354451a;
}
.Mixed {
  border-color: #f6b444;
  background-color: #f6b4441a;
}
.Favorable {
  border-color: #d6c746;
  background-color: #d6c7461a;
}
.Exceptional {
  border-color: #66cc99;
  background-color: #66cc991a;
}
