.wrapper {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
}

.wrapper p strong,
.wrapper p b {
  font-weight: 800;
}

.markdownBaseView {
  background: transparent !important;
}
