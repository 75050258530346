.filtersBlock {
  margin: 24px 0 15px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
}

.filtersBlock label {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}
.formWrapper {
  width: 100%;
  display: flex;
  gap: 16px;
}
.formItem {
  width: 100%;
}

.select {
  background-color: #fafafa;
  border-color: transparent;
}

.checkBoxContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  flex: 1;
  padding-top: 16px;
}
.checkBoxContainer p {
  font-family: "Raleway", sans-serif;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.analysisSearch .ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  min-height: 46px;
  border: none;
}

.analysisSearch
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-height: 46px;
  border: transparent;
}
