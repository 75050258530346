.wrapper {
  height: var(--doc-height);
  background-color: #f5f3ed;
  text-align: center;
  padding-top: 42px;
}

.logo {
  height: 45px;
  margin-bottom: 90px;
}

.logo img {
  height: 100%;
}

.contentContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  border: solid 1px #ffffff;
  background: #ffffff;
  box-shadow: 0 0 35px rgba(152, 152, 152, 0.25);
  border-radius: 20px;
  max-width: 40vw;
  min-width: 300px;
  padding: 40px 58px 54px 58px;
}

.wrapper h1 {
  font-weight: 800;
  font-size: 2rem;
  color: #253955;
  margin-bottom: 16px;
}

.wrapper p {
  font-size: 1rem;
}

@media (max-width: 992px) {
  .contentContainer {
    max-width: 90vw;
  }
  .wrapper h1 span {
    display: block;
  }
}
