.assessmentStatus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assessmentStatusActive {
}

.assessmentStatusInactive {
}

.assessmentStatus .assessmentStatusIcon {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 10px;
}

.assessmentStatus .assessmentStatusText {
  font-family: "Raleway", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.assessmentStatusActive .assessmentStatusIcon {
  background-color: #c39445;
}

.assessmentStatusInactive .assessmentStatusIcon {
  background-color: #c0b6a5;
}

.assessmentStatusActive .assessmentStatusText {
  color: #c39445;
}

.assessmentStatusInactive .assessmentStatusText {
  color: #c0b6a5;
}
