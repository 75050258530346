.mainMenu {
  border: none;
}

.menuItem {
  font-size: 14px;
  font-weight: 700;
  color: #122646;
  opacity: 0.5;
  text-transform: uppercase;
}
