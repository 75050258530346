.inRow {
  position: relative;
  padding-top: 24px;
}

.questionItem {
  margin: 6px 0;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #4e4e4e;
}

.questionItemAboutContainer {
  display: flex;
  min-width: 30%;
  flex-direction: row-reverse;
  justify-content: space-between;

  position: absolute;
  top: 0;
  right: 0;
}

.questionItemAbout {
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}

.questionItemContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-self: stretch;
  flex-grow: 0;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 30px 0 15px 0;
}

.questionDivider {
  margin: 15px 0;
}
.boldQuestionDivider {
  border-width: thick;
}

.editButton {
  border: none;
  color: #c4a567;
}

.editIcon {
  font-size: 23px;
}
