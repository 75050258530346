.scalesContainer {
  margin: 5px 20px 10px 20px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2em;
}

.scalesContainer span {
  font-weight: 700;
  padding-left: 6px;
}

.buttonContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.alertContainer {
  min-width: calc(100% - 120px);
}

.svgSize {
  height: 1.14em;
  width: 1.14em;
}

.language {
  text-align: end;
}
