.wrapper {
  background-image: url(../../../../assets/image/signInBackground.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding-top: 42px;
  height: var(--doc-height);
}

.container {
  display: inline-block;
  border: solid 1px #ffffff;
  background: #ffffff;
  box-shadow: 0 0 35px rgba(152, 152, 152, 0.25);
  border-radius: 20px;
  max-width: 300px;
  min-height: 250px;
  padding: 30px 20px 50px 20px;
}

.logo {
  height: 45px;
  margin-bottom: 74px;
}

.logoImage {
  height: 100%;
}

.title {
  margin-bottom: 26px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #4e4e4e;
}

.firebaseUi span {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-align: left;
}

.firebaseUi button {
  border-radius: 8px;
}

@media (min-width: 992px) {
  .container {
    min-width: 426px;
  }
}
