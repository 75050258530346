.background {
  background-color: #f5f3ed;
  background-image: url(src/assets/image/pattern.png);
  height: 100vh;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
  width: 55%;
  max-width: 500px;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-style: normal;

  h1 {
    margin-bottom: 30px;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2em;
    color: #4e4e4e;
    overflow-wrap: break-word;
  }

  :global(.ant-form-item) {
    display: block;

    :global(.ant-form-item-label) {
      margin-bottom: 6px;

      label {
        font-weight: 800;
        font-size: 1rem;
        line-height: 22px;
        color: #4e4e4e;

        &::after {
          display: none;
        }
      }
    }
  }
}

.emptyState {
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.fieldGroup {
  margin-bottom: 20px;

  ul {
    margin-bottom: 0;
  }
}

.sectionLabel {
  font-weight: 800;
  margin-bottom: 6px;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .mainContainer {
    width: 85%;
  }
}
