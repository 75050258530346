.container {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 40px #e3e3e3;
  font-size: 1rem;
  min-width: 100%;
  padding: 40px;

  :global(.ant-row.ant-form-item:last-child) {
    margin-bottom: 0;
  }

  :global(.ant-col.ant-form-item-label) label {
    font-size: 1rem;

    &::after {
      content: "";
    }
  }

  :global(.ant-row.ant-form-item) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
  }

  :global(.ant-col.ant-form-item-control) {
    margin-right: 8px;
    max-width: fit-content;
  }
}

.buttonContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.svgSize {
  height: 1.14em;
  width: 1.14em;
}
