.ant-dropdown-menu-title-content {
  display: flex;
  align-content: center;
}

/*.ant-radio-wrapper {*/
/*  padding: 0.4em;*/
/*}*/

/*.ant-radio-inner {*/
/*  width: 1.2em;*/
/*  height: 1.2em;*/
/*}*/

.ant-dropdown-menu-item {
  line-height: 28px;
}
