.chartCategoryContainer {
  margin-bottom: 2em;
}
.chartCategoryTitle {
  font-size: 24px;
  line-height: 1.167em;
  font-weight: 700;
  color: #4e4e4e;
  padding-bottom: 0;
  margin-bottom: 0.833em;
  text-align: center;
}

@media print {
  .chartCategoryContainer {
    page-break-inside: avoid;
  }
  .chartCategoryTitle {
    text-align: start;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);
  }
}
