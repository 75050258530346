.container {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 14px 24px 20px 24px;
  margin-bottom: 20px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #4e4e4e;
  margin-bottom: 1.5em;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.testName {
  font-weight: 600;
  line-height: 1.14em;
  text-transform: uppercase;
}

.checkboxGroup {
  font-weight: 400;
  line-height: 1.5em;
  margin-right: 0.5em;
}

.testColorMark {
  width: 1em;
  height: 1em;
  border-radius: 0.125em;
  margin-right: 0.5em;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1em;
  color: #444444;
  row-gap: 0.75em;
  column-gap: 1em;
  margin-bottom: 1.5em;
}

.selectContainer {
  display: flex;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 3px;
  cursor: pointer;
}
