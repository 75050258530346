.uploaderContainer {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 3px;
  background-color: transparent;
}

.photoUploader {
  width: 100%;
  height: 100%;
}

.imgContainer {
  object-fit: cover;
  /*padding-top: 80px;*/
}

.uploadTitle {
  height: 100%;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #505050;
}

.errorUpload {
  border-color: red;
}
