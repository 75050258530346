.wrapper {
  position: fixed;
  bottom: 55px;
  left: calc(50% - 43px);
  z-index: 2;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 1.25em;
}
