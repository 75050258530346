.suggestionsContainer {
  min-height: 30vh;
  padding-bottom: 0;
  color: #535353;
}

.suggestionsContainer h2 {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 700;
  color: #4e4e4e;
  padding-bottom: 0;
  margin-bottom: 0.75em;
}

.suggestionsContainer h3 {
  font-size: 18px;
  line-height: 1.25em;
  font-weight: 600;
  color: inherit;
  margin-bottom: 0.5em;
}
.suggestionsContainer h4 {
  font-size: 15px;
  line-height: 1.25em;
  font-weight: 400;
  color: inherit;
  margin-bottom: 1em;
}

.suggestionsContainer ul {
  margin-bottom: 1.25em;
  padding-left: 0;
}

.explanation {
  font-size: 14px;
  font-weight: 400;
  color: #4e4e4e;
  margin-bottom: 1em;
}

.suggestionsContainer li {
  margin-left: 1.25em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75em;
  color: inherit;
}

@media print {
  .suggestionsContainer {
    padding-top: 18px;
    min-height: 0;
  }

  .suggestionsContainer h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);
  }

  .suggestionsContainer h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.75);
  }

  .suggestionsContainer ul {
    page-break-inside: avoid;
  }

  .suggestionsContainer li {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
  }
  .suggestionTitle {
    page-break-inside: avoid;
  }
}
