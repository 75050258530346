.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 100%;
  padding: 16px 30px;
  margin: 0 auto;
  position: fixed;
  z-index: 999;
}
.inRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchBlock {
  justify-content: flex-end;
  width: 30%;
}

.header .ant-menu-item .ant-menu-title-content {
  color: #122646;
  font-weight: 500;
  padding: 2px;
}

.header .ant-menu-item-active .ant-menu-title-content,
.header .ant-menu-item-selected .ant-menu-title-content {
  font-weight: bold;
  width: 100%;
}

.header .ant-menu-item::after {
  bottom: -18px;
}

.header .ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 4px solid #122646;
}

.logo {
  width: 112px;
}

.logo-image {
  width: 100%;
}

@media print {
  .header {
    display: none;
  }
}
