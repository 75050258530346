:root {
  --primary-color: #122646;
  --secondary-color: #253955;
  --accent-color: #c39445;
  --secondary-accent-color: #d6bb8d;
  --light-background-color: #f5f3ed;
}

body,
html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 0.813em;
  color: #4a4a4a;
  line-height: 1.4;
}

#root {
  height: 100%;
  background-color: var(--light-background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: var(--primary-color);
  text-decoration: underline;

  &:hover {
    color: #0b182d;
    text-decoration: underline;
  }

  &:focus-visible {
    text-decoration: underline;
  }
}

a:focus-visible,
button:focus-visible {
  outline: 2px solid #096dd9;
}

p {
  margin: 0;
}

@media print {
  #root {
    /*height: inherit;*/
    background-color: #ffffff;
  }
}

/*{*/
/*  xs: '480px',*/
/*  sm: '576px',*/
/*  md: '768px',*/
/*  lg: '992px',*/
/*  xl: '1200px',*/
/*  xxl: '1600px',*/
/*}*/
