.tableTitleRow {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 0.75em;
  justify-content: space-between;
  text-align: left;
  color: #4e4e4e;
}

.tableTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0;
}

.tableButton {
  display: flex;
  justify-content: right;
}

.tableSearch {
  margin-left: 16px;
  flex-direction: column;
  align-self: center;
  vertical-align: bottom;
}
