.stage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.stageButton {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  text-decoration-line: underline;
  text-transform: uppercase;

  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  color: #122646;

  flex: none;
  order: 0;
  flex-grow: 0;

  &:hover {
    color: #0b182d;
  }
}

@media (max-width: 992px) {
  .stage {
    margin-bottom: 20px;
  }
}
