.modalContainer .ant-modal-content {
  max-height: 75vh;
  display: flex;
  flex-direction: column;
}

.modalContainer .ant-modal-body {
  flex: 1 1;
  overflow-y: auto;
}

.modalContainer .ant-modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.basicModalContainer .ant-modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.basicModalContainer .ant-modal-close-x {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}
