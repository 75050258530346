.testCompetenciesContainer {
  background: #ffffff;
  box-shadow: 2px 2px 29px #e4ded2;
  border-radius: 5px;
  padding: 30px 20px 20px 30px;
}

.blockHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 40px;
  margin-bottom: 20px;
}

.doubleButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.title {
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2em;
  color: #4e4e4e;
}

.buttonContainer {
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.alertContainer {
  min-width: calc(100% - 120px);
  text-align: left;
}

.svgSize {
  height: 1.14em;
  width: 1.14em;
}
