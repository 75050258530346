.answersRadioGroupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.answersRadioGroupContainerHorizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
  margin: 16px 0;
}

.answersRadioGroupContainerHorizontal .radio {
  margin-right: 6px;
  border-left-width: 1px;
}

.analysis {
  justify-content: space-between;
  margin-top: 20px;
  width: 60%;
}

.optionsAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio {
  margin: 0;
}

.radioSvg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.radioChecked {
}

@media (max-width: 992px) {
  .radio {
    margin: 4px 0;
  }
}
