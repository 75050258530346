/*TODO: implement color variables*/

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  height: initial;
}

.buttonLarge {
  padding: 1em 2em;
  line-height: 22px;
}

.buttonSmall {
  padding: 0.875em 1.75em;
  line-height: 16px;
}

.buttonXSmall {
  padding: 0.575em 1.25em;
  line-height: 16px;
}
.buttonXXSmall {
  padding: 0.5em 0;
  line-height: 16px;
}

.noChildL {
  padding-left: 22px;
}

.noChildS {
  padding-left: 16px;
}

.noChildXS {
  padding-left: 16px;
}

.buttonTitle {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.withIconBefore {
  margin-left: 0.5em;
}
.withIconAfter {
  margin-right: 0.5em;
}

.light {
  background: #fff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.light:hover,
.light:focus {
  background: var(--primary-color);
  color: #fff;
  border-color: inherit;
}

.dark {
  background: #122646;
  /*border-color: #253955;*/
}

.transparentDark {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #122646;
}

.transparentDark:disabled,
.transparentLight:disabled {
  background: transparent;
}

.transparentDark[disabled]:hover,
.transparentLight[disabled]:active,
.transparentLight[disabled]:focus {
  background: transparent;
}

.transparentLight {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #b0b0b0;
}

.transparentLight:hover {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #253955;
}

.transparentLight:focus {
  background: transparent;
  color: #253955;
}

.svgTransparentDark {
  stroke: #122646;
  fill: #122646;
}

.svgTransparentDark:hover .svgTransparentDark:active {
  stroke: #122646;
  fill: #122646;
}

.loading {
  padding-left: 0.5em;
}

.ant-click-animating-node {
  animation-fill-mode: none;
  animation: none;
  box-shadow: none;
}
