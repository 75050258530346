.analysisTestElement {
  padding: 12px 15px;
  border-radius: 3px;
}
.changedTestElement {
  background-color: rgba(24, 144, 255, 0.08);
}

.requiredTestElement {
  background-color: #f5f3ed;
}

.scalesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(256, 256, 256, 0.2);
  backdrop-filter: blur(5px);
  z-index: 100;
}

.divider {
  margin: 8px 15px;
  border-color: #efefef;
  min-width: auto;
  width: auto;
}

.thickDivider {
  border-width: thick;
}
