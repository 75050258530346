.addTestContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 30px;
}

.selectContainer {
  display: flex;
  text-align: left;
  margin-bottom: 20px;
  min-width: 30%;
  max-width: 60%;
}

.text {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  color: #4e4e4e;
  text-align: left;
  padding-top: 1.5em;
}

.selectContainer .ant-select-selector {
  height: 100%;
}

.selectContainer .ant-select {
  background-color: #ffffff;
  min-width: 200px;
}

.selectContainer .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
  padding-right: 2em;
}

.selectContainer .ant-select:not(.ant-select-customize-input) {
  min-height: 42px;
  border-color: transparent;
  border-radius: 100px;
  /*border-radius: 0.25em;*/
}
.selectContainer .ant-select-multiple .ant-select-selection-item-content {
  color: rgba(0, 0, 0, 0.4);
}

.option {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14em;
  text-transform: uppercase;
  color: #4e4e4e;
  padding: 0.86em 1.5em;
}

.ant-select-dropdown {
  margin-top: 10px;
  border-radius: 5px;
}

.ant-select-item-option-content {
  min-height: 30px;
  line-height: 30px;
}

.ant-select-item-option {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  align-items: flex-end;
  color: #4e4e4e;
}
