.ant-tag {
  margin-right: 0;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: inherit;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .ant-tag-has-color {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1em;
  padding: 0.36em 0.57em;
  text-align: left;
  white-space: normal;
}

.anticon-close,
.anticon-close:hover {
  font-size: 10px;
  opacity: 0.5;
  padding: 0.25em;
}

.ant-select-multiple .ant-select-selection-item {
  text-transform: none;
  background-color: transparent;
  border: none;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  color: #253955;
  padding: 0;
  margin-top: 7px;
}
