.testInfoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}

.hideInfo {
  visibility: hidden;
}

.text {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.bold {
  font-weight: 700;
}

.upper {
  text-transform: uppercase;
}

.muted {
  text-transform: uppercase;
  opacity: 0.5;
}

.delimiter {
  margin: 0 4px;
}

@media (max-width: 992px) {
  .text {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
