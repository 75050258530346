.container {
  display: block;
  width: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  min-width: 70px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  height: 18px;
}

.wrapper p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #4e4e4e;
  margin-left: 12px;
}

.wrapper span {
  opacity: 0.25;
  font-size: 18px;
}
