.sharedReportWrapper {
  min-height: var(--doc-height);
  background-color: #e7e3d7;
}

.header {
  padding: 20px 30px;
  background-color: #f5f3ed;
}

.logosContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.assessmentDetails {
  margin-top: 12px;

  h1 {
    font-weight: 800;
    font-size: 1.75rem;
    color: #4a4a4a;
    margin-bottom: 6px;
  }

  .jobDetailsContainer {
    display: flex;
    gap: 20px;
  }
}

.logoContainer {
  height: 35px;
}

.clientName {
  font-size: 1.2rem;
  font-weight: bold;
}

@media print {
  .sharedReportWrapper {
    background-color: #ffffff;
  }
  .header {
    display: none;
  }
}
