.container {
  bottom: 0;
  background: #e7e3d7;
  min-height: calc(100vh - 315px);
  padding: 15px 0;
}

.wrapper {
  margin-bottom: 30px;
}

.tabs-inner-container-desktop {
  padding: 15px 30px;
}

.tabs-inner-container-mobile {
  padding: 30px 11px;
}

@media print {
  .container {
    background-color: #ffffff;
  }
  .tabs .ant-tabs-nav {
    display: none;
  }
}
