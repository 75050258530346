.ant-statistic {
  background: #ffffff;
  border-radius: 3px;
  padding: 10px;
  margin: 10px;
  min-height: 112px;
}

.ant-statistic-title {
  text-align: left;
  margin-bottom: 40px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4e4e4e;
  opacity: 0.7;
}
.ant-statistic-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-statistic-content-prefix {
  flex-grow: 2;
}

.ant-statistic-content-value {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  color: #253955;
}
.ant-statistic-content-suffix {
  opacity: 0.1;
}

.progressWrapper .ant-progress-inner {
  border-radius: 3px !important;
}

.progressWrapper .ant-progress-inner,
.ant-progress-bg {
  border-radius: 3px !important;
}
.progressWrapper .ant-progress-success-bg,
.ant-progress-bg {
  border-radius: 3px !important;
}
.progressElement {
  padding: 0 20px;
  bottom: 80px;
}

.progress-tooltip {
  color: #4e4e4e;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
}

.sub {
  padding-left: 1em;
}

.finished {
  color: #225082;
}

.in-progress {
  color: #96b5f0;
}
