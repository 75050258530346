.loaderContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.loader {
  color: #613a28 !important;
  font-size: 80px;
}

.smallContainer {
  height: 32px;
}

.smallLoader {
  font-size: 22px;
}

.defaultContainer {
  height: 40px;
}

.defaultLoader {
  font-size: 32px;
}
