.background {
  background-color: #f5f3ed;
  background-image: url(src/assets/image/pattern.png);
  height: 100vh;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32vh;
}

.candidatesContainer {
  padding-top: 24vh;
}

.innerContainer {
  width: 32vw;
}

.inputTitle {
  margin-bottom: 1em;
}

.inputTitle input,
.inputTitle textarea {
  border: none;
  padding: 12px 16px;
  border-radius: 3px;
}

.inputTitle > .ant-form-item .ant-form-item-label {
  padding: 0;
}

.inputTitle label {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  color: #4e4e4e;
}

/*.inputType {*/
/*  padding: 11px 24px;*/
/*  border-radius: 3px;*/
/*  border: none;*/
/*}*/

.instructions {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
}

.instructions p {
  text-align: left;
}

.buttonContainer {
  margin: 1.5em 0;
}

@media (max-width: 992px) {
  .innerContainer {
    width: 80vw;
  }
}
