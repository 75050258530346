.answersContainer {
  padding: 16px 0 0;
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 14px;
}

.analysis {
  gap: 60px;
}
