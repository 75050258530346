.jobMatchLabel {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 0.75em;
  border-radius: 3px;
}
.jobMatchLabel span {
  font-weight: 500;
  color: #4e4e4e;
}

.Unfavorable {
  color: #c35445;
  background-color: #c354451a;
}
.Mixed {
  color: #f6b444;
  background-color: #f6b4441a;
}
.Favorable {
  color: #d6c746;
  background-color: #d6c7461a;
}
.Exceptional {
  color: #66cc99;
  background-color: #66cc991a;
}

@media print {
  .jobMatchLabel {
    padding: 11px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    border-radius: 4px;
  }
  .jobMatchLabel span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}
